<!-- 积分规则  -->
<template>
  <div class="">
    <a-menu v-model="activeTab" mode="horizontal">
      <a-menu-item key="1">加分项</a-menu-item>
      <a-menu-item key="2">减分项</a-menu-item>
    </a-menu>
    <!-- table模块 -->
    <!-- 根据tab或者menu的切换只改变表格api的写法关键点就是表格的key要和activeTab的值一致 -->
    <ax-table
      :key="activeTab[0]"
      ref="tableBox"
      :columns="columns"
      :show-search="true"
      :searchForm="searchForm"
      :showToolBar="true"
      :toolActions="toolActions"
      @add="add"
      :dataSourceApi="dataSourceApis"
      :dataSourceParams="dataSourceParams"
      :scroll="{ y: '55vh', x: '80vw' }"
    >
      <template slot="roleType" slot-scope="{ record }">
        <span v-if="record.roleType === '1'">参加社区活动</span>
        <span v-else-if="record.roleType === '2'">完成社区活动评分</span>
        <span v-else-if="record.roleType === '3'">参加志愿者活动</span>
        <span v-else-if="record.roleType === '4'">居民点单</span>
        <span v-else-if="record.roleType === '5'">积分调整</span>
        <span v-else-if="record.roleType === '6'">积分申请</span>
        <span v-else-if="record.roleType === '7'">其他</span>
      </template>
    </ax-table>

    <addRule ref="addRule" @refresh="refresh"></addRule>
  </div>
</template>

<script>
import addRule from "./components/addRule.vue";
const searchForm = [
  {
    label: "类别",
    type: "input",
    model: "businessName",
    options: { placeholder: "活动/类别名称" },
    formItem: {},
    col: { span: 6 },
  },
  {
    label: "所属网格",
    type: "cascader",
    model: "gridId",
    options: { placeholder: "请选择" },
    formItem: {},
    col: { span: 6 },
  },
];
import api from "./api";
//这里可以导入其他文件(比如:组件,工具js,第三方插件js,json文件,图片文件等等)
//例如:import <组件名称> from '<组件路径>';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: { addRule },
  data() {
    //这里存放数据
    return {
      api,
      activeTab: ["1"],
      columns: this.$ax.tools.initColumn(
        [
          {
            title: "类别",
            dataIndex: "roleType",
            ellipsis: false,
            width: 80,
          },
          {
            title: "活动/类别名称",
            dataIndex: "businessName",
            ellipsis: true,
            width: 190,
          },
          {
            title: "分值",
            dataIndex: "points",
            ellipsis: true,
            width: 70,
          },
          {
            title: "所属网格",
            dataIndex: "gridName",
            ellipsis: true,
            width: 100,
          },
        ],
        true
      ),
      searchForm: this.$common.initGridFormData(searchForm, {
        layout: "horizontal",
        labelWidth: 70,
      }),
      toolActions: [{ name: "add", text: "新增" }],
      selectedRowKeys: [],
      dataSourceApis: api.list,
      dataSourceParams: { type: "0" },
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    // tab切换
    activeTab(newValue) {
      if (newValue[0] === "1") {
        this.dataSourceParams = { type: "0" };
      } else if (newValue[0] === "2") {
        this.dataSourceParams = { type: "1" };
      }
      // 在这里更新了数据源后，如果您的表格组件正确地绑定了 dataSourceApi，它应该会自动重新渲染以显示新的数据。
    },
  },
  //方法集合
  methods: {
    // 获取字典
    async getDictionsary() {
      // 积分类别
      await api.dictData({ dicKind: "gz_type" }).then((res) => {
        const options = res.data.map((res) => {
          return { label: res.dicDesc, value: res.dicCode };
        });
        this.$refs.tableBox.$children[0].$refs.searchForm.setFormItemProp(
          "roleType",
          { options: { options, allowClear: true, placeholder: "请选择" } }
        );
      });
    },
    async getgridTree() {
      try {
        const res = await api.gridTree(); // 调用接口获取数据
        if (res.success) {
          // 如果接口请求成功
          const options = this.processData(res.data); // 处理数据
          this.$refs.tableBox.$children[0].$refs.searchForm.setFormItemProp(
            "gridId",
            {
              options: {
                options,
                allowClear: true,
                placeholder: "上级网格",
                changeOnSelect: true,
              },
            }
          );
        } else {
          console.error("获取所属网格数据失败:", res.message); // 输出错误信息
        }
      } catch (error) {
        console.error("获取所属网格数据异常:", error); // 输出异常信息
      }
    },
    // 处理数据为级联选择器格式
    processData(data) {
      return data.map((item) => ({
        value: item.id,
        label: item.name,
        children: item.children ? this.processData(item.children) : [], // 递归处理子节点
      }));
    },

    add() {
      this.$refs.addRule.openModal({ activeTab: this.activeTab });
    },
    refresh() {
      this.$refs.tableBox.getDataSource();
    },
  },
  //生命周期 - 创建完成(可以访问当前this实例)
  created() {},
  //生命周期 - 挂载完成(可以访问DOM元素)
  mounted() {
    // this.getDictionsary();
    this.$nextTick(() => {
      this.getgridTree();
    });
  },
  beforeCreate() {}, //生命周期 - 创建之前
  beforeMount() {}, //生命周期 - 挂载之前
  beforeUpdate() {}, //生命周期 - 更新之前
  updated() {}, //生命周期 - 更新之后
  beforeDestroy() {}, //生命周期 - 销毁之前
  destroyed() {}, //生命周期 - 销毁完成
  activated() {}, //如果页面有keep-alive缓存功能,这个函数会触发
};
</script>
<style lang='less' scoped>
/deep/.ant-menu-item-selected {
  border-bottom: 2px solid #556bff !important;
  color: #556bff !important;
}

/deep/.ant-menu-item:hover {
  border-bottom: 2px solid #556bff !important;
  color: #556bff !important;
}
</style>